//
// Perch Scripts
// ----------------------------------------------------------------------------
// Scripts we often need for making Perch sites.
// * not meant to be a one-size-fits-all solution like Perch Core.
//   » (not yet, at least)
// * feel free to pick and choose from these in making your own scripts file
// ----------------------------------------------------------------------------



// Default Fixes / Resets
// --------------------------------------------------

// Remove the scrolling increment/decrement behavior from number input elements
$(':input[type=number]').on('mousewheel', function(e){
	$(this).blur();
});

// Exclude hidden elements from Parsley
if (typeof Parsley !== 'undefined' && typeof Parsley.options !== 'undefined') {
	Parsley.options.excluded = 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden';
}



// Enhancements, Initializations, etc.
// --------------------------------------------------

// Preloading!
// ---------------------------------------
// When the page is loaded, add & remove classes from the body element
$(window).on('load', function() {
	$("body").addClass("page-loaded");
	$("body").removeClass("page-preloading");
});


// Navigation / Menus
// ---------------------------------------

// Variables
// -------------------------------
var $global_header 			= $('#global-header'),

	$nav_outer_wrapper 		= $('#global-outer-navigation-wrapper'),

	$nav_reveal_button 		= $('.hamburger-menu-button'),

	$nav_parent 			= $('.global-nav-item--has-children'),
	$nav_active_parent 		= $('.global-nav-item--has-children.active-parent'),
	$nav_parent_link 		= $('.global-nav-item--has-children > .global-nav-link'),
	$nav_child 				= $('.global-nav-child-list'),

	$main_nav 				= $('#global-main-nav'),
	$main_nav_parent 		= $('.global-main-nav-list > .global-nav-item--has-children'),

	$nav_link_opens_child 	= $('.global-nav-item--opens-child > .global-nav-link'),
	$nav_parent_has_button 	= $('.global-nav-item--has-child-nav-opener'),

	$utility_nav 			= $('#global-utility-nav'),

	$search_menu 			= $('#global-search-overlay'),
	$search_input 			= $('#global-search-overlay__input'),
	$search_submit 			= $('#global-search-overlay__submit'),
	$search_close 			= $('.global-search-overlay__corner-closer'),
	$search_close_btn 		= $('#global-search-overlay__close-button'),

	$search_trigger 		= $('.global-search-trigger'),
	$search_reveal_button	= $('.global-search-reveal-button');

var nav_close_timer;


// Functions
// -------------------------------


// Navigation opening
// --------------------------
// More complex function, that opens the menu for both mobile and desktop. Also can accept an argument to open a specific child menu.
function open_nav (the_menu) {

	// Use interpolation to take the passed child menu name and then select it below
	var interpolated_selector = ".menu-" + the_menu;
	$(interpolated_selector)
		.focus()
		.addClass('is-open submenu-is-open');
	$(interpolated_selector).children($nav_child).addClass('is-open');
	$(interpolated_selector).children('.child-nav-opener').addClass('is-active');

	// Feedback to the body: a child menu is open. Allows for making a backdrop show up over the content/beneath the menu.
	// $('body').addClass('child-menu-is-open');

	open_mobile_nav();
}

// Mobile nav toggling. This is a basic open function for the mobile menu.
function open_mobile_nav () {
	$('html').addClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_reveal_button.addClass('is-active').attr('aria-expanded', 'true');
	$nav_outer_wrapper.addClass('is-active');
	$global_header.addClass('mobile-nav-is-open');
}


// Navigation closing
// --------------------------
function close_nav () {
	$('html').removeClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_outer_wrapper.addClass('animating-out');
	$nav_reveal_button.removeClass('is-active').attr('aria-expanded', 'false');
	$global_header.removeClass('mobile-nav-is-open');

	// Close megamenus for mobile...before animation is finished
	$('.global-nav-item').removeClass('is-open submenu-is-open submenu-is-mobile-open');
	$('body').removeClass('child-menu-is-open');

	var nav_close_timer = setTimeout(function() {
		// Close megamenus for mobile...after animation is finished
		$('.child-nav-opener').removeClass('is-active');
		$nav_child.removeClass('is-open');

		$nav_outer_wrapper.removeClass('is-active');
		$nav_outer_wrapper.removeClass('animating-out');
	}, 250);

	// also close the search
	close_search();

	return false;
}


// Search opening
// --------------------------
function open_search () {
	// First, make the link highlighted & show the menu
	$search_reveal_button.addClass('is-active');
	$search_menu.addClass('is-active');
	// Then, select the input field
	$search_input.select();
}


// Search closing
// --------------------------
function close_search () {
	// Remove classes to the menu and the link
	// $(this).offsetWidth = $(this).offsetWidth;
	$search_menu.addClass('animating-out');
	setTimeout(function() {
		$search_reveal_button.removeClass('is-active');
		$search_menu.removeClass('is-active');
		$search_menu.removeClass('animating-out');
	}, 250);
	return false;
};


// Logic
// ---------------------------------------
$(document).ready(function() {

	// Navigation
	// --------------------------

	// Close the menu if it's currently active. Otherwise: run open_nav function.
	$nav_reveal_button.click(function() {
		if ($(this).hasClass('is-active')) {
			close_nav();
			this.blur(); // Remove the focus from the button so :focus styles don't show on touch screens

		} else {
			open_nav();
		}

		return false;
	});

	// If a special nav link set to open its child is clicked, open the child menu
	$nav_link_opens_child.click(function(e) {
		// Prevent link from being followed
		e.preventDefault();

		// set the parent
		var $the_parent = $(this).parent();

		// remove is-open class if it's already there
		if ( $the_parent.hasClass('submenu-is-open') || $the_parent.hasClass('submenu-is-mobile-open') ) {
			$the_parent.removeClass('submenu-is-open submenu-is-mobile-open')
				.children('.global-nav-child-list').removeClass('is-open');
			$(this).blur();
		}
		// otherwise, add it
		else {
			$the_parent.addClass('submenu-is-open')
				.children('.global-nav-child-list').addClass('is-open');
		}
	});


	// If $nav_parent_has_button, generate button
	// ---------------------------------------
	// Dynamically add buttons after each child list
	// that listen for click, which triggers open/close of the child list
	// ---------------------------------------

	// Create the buttons and the function they run
	var child_nav_opener = $('<button class="child-nav-opener"><span class="sr-only">Open and close submenu</span><span class="opened-state"></span><span class="closed-state"></span></button>').click(function () {
		if ( $(this).hasClass('is-active') ) {
			$(this).removeClass('is-active');
			$(this).next().removeClass('is-open is-mobile-open');
			$(this).parent().removeClass('submenu-is-open submenu-is-mobile-open');
		}
		else {
			$(this).addClass('is-active');
			$(this).next().addClass('is-open');
			$(this).parent().addClass('submenu-is-open');
		}
	});

	// Add the buttons!
	$nav_parent_has_button.children('.global-nav-link').after(child_nav_opener);

	// If an .active-parent (our custom WP walker's way of noting a current page's navigation parent) is found, Open/Activate menus to have it open by default in the mobile menu
	$nav_active_parent.addClass('submenu-is-mobile-open');
	$nav_active_parent.children(child_nav_opener).addClass('is-active');
	$nav_active_parent.children('.global-nav-child-list').addClass('is-mobile-open');
	// ---------------------------------------


	// Search
	// --------------------------

	// Close the menu if it's currently active. Otherwise: run open_search function.
	$search_reveal_button.click(function() {
		if ($(this).hasClass('is-active')) {
			close_search();
		}

		else {
			open_search();
		}
	});


	// Open the search menu if any anchor with a certain class is clicked
	$search_trigger.click(function() {
		// Add classes to the menu and the link
		$search_reveal_button.addClass('is-active');
		$search_menu.addClass('is-active');

		// Focus the search input
		$(this).blur();
		$search_input.select();

		return false;
	});


	// Clicking anywhere in the search's background overlay — except within the actual form — closes the overlay
	$search_menu.click(close_search);
		// ...Do not close if the click is inside the inner container.
		$('.global-search-overlay__inner').click(function(event) {
			event.stopPropagation();
		});
	$search_close.click(close_search);
	$search_close_btn.click(close_search);


	// Search keyboard assist: when search menu is open, keep focus within the search menu
	// by focusing "back to" the corner closer button, which allows the user to close if desired,
	// or continue to the next focusable field, the input itself
	$search_submit.on('focusout', function() {
		$search_close.focus();
	});


	// NOTE:: CURRENTLY, THIS BREAKS THE $nav_link_opens_child FUNCTIONALITY
	// // Notify body hovered nav is open
	// // --------------------------
	// // Note: we need to be sure that this only selects top parents, otherwise, if all parents in the tree are selected, the mouseleave will be fired when mouse moves away from them.
	// $main_nav_parent
	// 	.mouseenter(function (e) {
	// 		$('body').addClass('child-menu-is-open');
	// 	})
	// 	.mouseleave(function (e) {
	// 		$('body').removeClass('child-menu-is-open');
	// 		$(this).removeClass('is-open submenu-is-open submenu-is-mobile-open');
	// 	});


	// ☠☠☠☠ DEPRECATED ☠☠☠☠
	// Deprecated because:
	//  - this should not be a core script,
	//  - it relies on using jQuery code removed from jQuery (in the "slim" release, at least)
	//  - it is too specific. abstract code for all jump links or bust
	//  - it should be achievable with various jump scrolling animation/assist libraries out there
	// // Footnotes
	// // --------------------------

	// // Footnote back link scrolling help if sticky header is covering the target
	// $('.footnote-back-link').on('click', function(scroll) {
	// 	var $clicked_link = $(this);

	// 	setTimeout(function() {
	// 		if( $global_header.hasClass('show') ) {

	// 			var destination = $clicked_link.attr('href');
	// 			var $header_height = $global_header.outerHeight();

	// 			$('html, body').animate({
	// 				scrollTop: $(destination).offset().top-$header_height
	// 			}, 200);
	// 		}
	// 	}, 10);
	// });
	// end deprecated code ☠☠☠☠

});



// Keyboard shortcuts with MouseTrap.js
// --------------------------------------------------
$(document).ready(function(){

	// open search
	Mousetrap.bind('/', function(e) {

		// If the search menu is already open, nevermind...
		if ($search_menu.hasClass('is-active')) {
			return true;
		}

		// But if it's not already open, let's capture the / char and then open the menu
		else {
			// Prevent '/' char from being typed into the newly-focused search field
			e.preventDefault();

			open_search();
		}
	});

	// press escape (ESC) to close menus
	Mousetrap.bind('esc', function() {
		close_nav();
		close_search();
	});

	// open the menu/nav
	Mousetrap.bind('u', function() {
		// Since search input is allowed to listen to Mousetrap (for ESC key close), ignore the "u" character if it is open
		if ($search_menu.hasClass('is-active')) {
			return true;
		}

		// Otherwise, if the mobile navigation is open, close it
		else if ($global_header.hasClass('mobile-nav-is-open')) {
			close_nav();
		}

		// ...and if it's not open, open it
		else {
			open_nav();
		}
	});

	// // konami code!
	// Mousetrap.bind('up up down down left right left right b a', function() { //enter
	// 	$('body').addClass('konami-code-is-active');
	// });

});



// Bootstrap Dismissable Alert
// --------------------------------------------------
// Note: .alert CSS was built from the
// 		 ground up by @k_bav as an exercise.
//		 This JS is Bootstrap's for .alert dismissal.
// --------------------------------------------------
// $(document).ready(function(){
// 	$(".alert").alert();
// });



// Disappearing & reappearing Sticky Nav
// --------------------------------------------------
// via http://www.netvlies.nl/blog
// --------------------------------------------------
$(document).ready(function() {
	var a = $("#global-header"), e = 0, f = window.pageYOffset, d = a.outerHeight(), g = window.location.hostname; // formerly used window.scrollY but IE10 does not support it

	$(window).on("scroll", function() {
		f = e;
		e = window.pageYOffset; // formerly used window.scrollY but IE10 does not support it

		// Only proceed IF the mobile nav is not open
		if (!$global_header.hasClass('mobile-nav-is-open')) {
			f < e ? e > d && a.removeClass("show show-not-top fast") : (a.addClass("show show-not-top"), e < d && a.addClass("fast"));

			if (e <= 0) {
				a.removeClass("show-not-top");
			};
		};
	});
});
