//
// Tiller Group site-specific scripts
// --------------------------------------------------


// Trigger the My List Modal when clicking on the "My List" link in the nav
// ---------------------------------------
$('#menu-main-menu').on('click', '.menu-my-list', function (event) {
	event.preventDefault();
	close_nav();
	$('#global-my-list-modal').modal('show');
});


// Add the count of speakers to My List
// ---------------------------------------
// $(".menu-my-list > .global-main-nav-link").append('<span class="number-of-speakers-badge">3</span>');


// asdf
// ---------------------------------------
$('.accordion-to-tabs-featured-video-link').on('click', function(event){
    event.preventDefault();

    // console.log(this);
    AccordionToTabs.remoteActivateTab( $(this).attr('href') );

    // play the featured video
    $('.video-column:first-child iframe')[0].src += "&autoplay=1";
});



window.onload = function(){

    // Lazy-load video iframes
    // ---------------------------------------
    $('iframe[data-url]').each(function(i){
        $(this).attr('src', $(this).data('url'));
    });

    // Lazy-load images outside flickity
    // ---------------------------------------
    $('.product-listing:not(.product-listing--dynamic-slider)').find('img[data-flickity-lazyload]').each(function(i){
        var lazy = $(this).data('flickity-lazyload');
        if(lazy) {
            $(this).attr('src', lazy);
        }
    });

};

// Formidable forms
// ---------------------------------------
$(function(){
    $('.page-template-page-formidable-form').on('shown.bs.collapse', '[id^="collapse"]', function(){
        $('html, body').animate({
            scrollTop: $(this).closest('.panel').offset().top - 20
        }, 350);
    });

    window.Parsley.on('form:error', function(){
        var $first = $('.parsley-error:first');
        var $parent = $first.closest('.collapse');
        if($parent.length && !$parent.hasClass('in')) {
            $('[data-toggle="collapse"][href="#'+$parent.attr('id')+'"]').click();
            return;
        }

        $('html, body').animate({
            scrollTop: $first.prev('label').offset().top - 30
        }, 350);
    });

    $('.accordion-continue').on('click', function(event){
        event.preventDefault();

        var $me = $(this);
        var error = 0;

        $me.closest('.accordion-collapse').find(':input:not(:button)').each(function(index, value){
            var validate = $(this).parsley().validate();
            if(validate !== true) {
                error++;
            }
        });

        if(error == 0) {
            $me.closest('.accordion-panel').next().find('h2 > [data-toggle="collapse"]').click();
        }
    });
});

// google analytics tracking
// ---------------------------------------
$(function(){
    if(typeof __gaTracker !== 'function') {
        return;
    }

    __gaTracker(function(){
        window.ga = __gaTracker;
    });

    $('body').on('click', '[data-track-event]', function(){
        var trackEventData = $(this).data('track-event') || '';
        if(trackEventData.length > 0) {
            var eventArray = trackEventData.split(',');
            // Make sure there are at least 2 values
            if(eventArray.length >= 2) {
                // Split up the track event data into GA variables
                var trackCategory = eventArray[0], // Required (String)
                    trackAction = eventArray[1], // Required (String)
                    trackLabel = eventArray[2], // Optional (String)
                    trackValue = eventArray[3]; // Optional (Number)
                // Send event data to GA
                __gaTracker('send', 'event', trackCategory, trackAction, trackLabel, trackValue);
            }
        }
    });
});