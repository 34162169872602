(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['speaker-list-item'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"speaker\" data-sid=\""
    + alias4(((helper = (helper = helpers.sid || (depth0 != null ? depth0.sid : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sid","hash":{},"data":data}) : helper)))
    + "\">\n    <button type=\"button\" class=\"my-list-speaker-removal-button button-corner-close--minimal remove\" aria-label=\"Remove speaker\" data-tsml-uuid=\""
    + alias4(((helper = (helper = helpers.uuid || (depth0 != null ? depth0.uuid : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data}) : helper)))
    + "\"><span aria-hidden=\"true\">X</span></button>\n    <div class=\"speaker-photo-wrapper\">\n        <a href=\""
    + alias4(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "\">\n            <img class=\"speaker-photo img-responsive\" src=\""
    + alias4(((helper = (helper = helpers.photo || (depth0 != null ? depth0.photo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"photo","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " photo\">\n        </a>\n    </div>\n    <div class=\"speaker-info-wrapper\">\n        <h4 class=\"speaker-name\">\n            <a href=\""
    + alias4(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "\">\n                "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n            </a>\n        </h4>\n        <div class=\"speaker-info\">\n            <p>"
    + alias4(((helper = (helper = helpers.short_bio || (depth0 != null ? depth0.short_bio : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"short_bio","hash":{},"data":data}) : helper)))
    + "</p>\n        </div>\n        <div class=\"speaker-ctas\">\n            <a class=\"speaker-availability-check-button button\" href=\""
    + alias4(((helper = (helper = helpers.check_url || (depth0 != null ? depth0.check_url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"check_url","hash":{},"data":data}) : helper)))
    + "\">Check Availability</a>\n        </div>\n    </div>\n</li>";
},"useData":true});
})();