$.fn.extend({
    disable: function(){
        return this.each(function(){
            $(this).prop('disabled', true);
        });
    },
    enable: function(){
        return this.each(function(){
            $(this).prop('disabled', false);
        });
    }
});

var speakersPageUrl = '/speakers/';

var tsmlEmptyMarkup = '<li class="text-center">' +
    '<p>You speaker list is empty.</p>' +
    '<p><a class="button" href="/speakers/">Search Speakers</a></p>' +
'</li>';

var tsmlPopoverTemplate = '<div class="popover" role="tooltip">' +
    '<div class="arrow"></div>' +
    '<h3 class="popover-title"></h3>' +
    '<div class="popover-content text-smaller"></div>' +
'</div>';

var tsmlLoginAlert = '<div class="text-center"><a class="link-tertiary-color" href="/login/">Create an account</a>' +
    ' or <a class="link-tertiary-color" href="/login/">login</a> to save this&nbsp;speaker.</div>';

var TsmlSpeakers = {

    beforeSend: function(xhr) {
        xhr.setRequestHeader('X-WP-Nonce', wpApiSettings.nonce);
    },

    getIdentifier: function() {
        return Number(tsmlApiSettings.user_id) !== 0
            ? tsmlApiSettings.user_id
            : tsmlApiSettings.cookie_id
    },

    fetch: function() {
        return $.ajax({
            url: wpApiSettings.root + 'tsml/v1/list/' + this.getIdentifier(),
            method: 'GET',
            beforeSend: this.beforeSend,
            cache: false
        });
    },

    save: function(uuid) {
        return $.ajax({
            url: wpApiSettings.root + 'tsml/v1/list/' + this.getIdentifier(),
            method: 'POST',
            beforeSend: this.beforeSend,
            cache: false,
            data: { 'speaker_uuid': uuid }
        });
    },

    delete: function(uuid) {
        return $.ajax({
            url: wpApiSettings.root + 'tsml/v1/list/' + this.getIdentifier() + '/' + uuid,
            method: 'DELETE',
            beforeSend: this.beforeSend,
            cache: false
        });
    },

    clear: function() {
        return $.ajax({
            url: wpApiSettings.root + 'tsml/v1/list/' + this.getIdentifier(),
            method: 'DELETE',
            beforeSend: this.beforeSend,
            cache: false
        });
    },

    count: function() {
        if(Number(tsmlApiSettings.user_id) === 0 && tsmlApiSettings.cookie_id === '') {
            return;
        }

        $.ajax({
            url: wpApiSettings.root + 'tsml/v1/list/' + this.getIdentifier() + '/count',
            method: 'GET',
            beforeSend: this.beforeSend,
            cache: false
        })
        .fail(function(xhr){ console.log(xhr); })
        .done(function(response){
            if(response.count === undefined) {
                return;
            }
            if(Math.max(0, Number(response.count)) > 0) {
                $(".menu-my-list > .global-main-nav-link").append('<span class="number-of-speakers-badge">'+response.count+'</span>');
                $(".hamburger-menu-button").append('<span class="number-of-speakers-badge">'+response.count+'</span>');

                // do something with uuids
                if(response.uuids !== undefined && response.uuids.length > 0) {
                    TsmlSpeakers.checkSaved(response.uuids);
                }
            } else {
                $(".menu-my-list > .global-main-nav-link, .hamburger-menu-button").find('.number-of-speakers-badge').remove();
            }
        });
    },

    checkSaved: function(uuids) {
        var $btns = $('.tsml-save-speaker');
        if($btns.length > 0) {
            $btns.each(function(){
                var $me = $(this);
                if($.inArray($me.attr('data-tsml-uuid'), uuids) > -1) {
                    $me.prop('disabled', true).html(
                        '<span class="icon-checkmark"></span> Saved to List'
                    );
                }
            });
        }
    }

};

var TsmlError = {

    fail: function(xhr) {
        var message = "An unknown error occurred. Try again."

        if(xhr.hasOwnProperty('responseJSON') && xhr.responseJSON.hasOwnProperty('code')) {
            var json = xhr.responseJSON;

            switch(json.code) {
                case 'duplicate_entry':
                    message = 'You\'ve already saved this speaker to your list.';
                    break;
                default:
                    break;
            }
        }

        this.popover({
            content: message,
            placement: 'auto top',
            trigger: 'manual',
            template: tsmlPopoverTemplate
        }).popover('show');
    }

};

function loadSpeakersIn(element) {
    TsmlSpeakers.fetch()
        .fail(function(xhr){ console.log(xhr); })
        .done(function(response){
            if(Array.isArray(response) && response.length > 0) {
                element.html('');
                var sids = [];
                response.forEach(function(el){
                    if(!el.speaker) {
                        return;
                    }

                    sids.push(el.id);

                    var data = el.speaker;
                        data.sid = el.speaker.id;
                        data.page = speakersPageUrl;
                        data.check_url = '/forms/check-availability/?sid[]='+encodeURIComponent(el.speaker.id);

                    var speaker = Handlebars.templates['speaker-list-item'](data);
                    element.append(speaker);
                });
                element.closest('.speakers-review-list-wrapper')
                    .addClass('speakers-list-has-items');

                $('.check-for-all')
                    .attr('data-sid', JSON.stringify(sids))
                    .data('sid', sids);
            } else { // no speakers yet
                element.html(tsmlEmptyMarkup);
            }
        });
}


$(function(){

    $(window).on('mousedown', function(event){
        if($(event.target).parents('.popover.in').length === 0) {
            $('.popover').popover('hide');
        }
    });

    // Save speaker
    // ---------------------------------------
    $('body').on('click', '.tsml-save-speaker', function(){
        var $me = $(this);

        // if(!tsmlApiSettings.hasOwnProperty('user_id') || Number(tsmlApiSettings.user_id) === 0) {
        //     $me.popover({
        //         html: true,
        //         content: tsmlLoginAlert,
        //         placement: 'auto top',
        //         trigger: 'manual',
        //         template: tsmlPopoverTemplate
        //     }).popover('show');
        //     return;
        // }

        $me.disable();

        TsmlSpeakers.save( $me.data('tsml-uuid') )
            .always(function(){ $me.enable(); })
            .fail(TsmlError.fail.bind($me))
            .done(function(response){
                if(response.hasOwnProperty('added') && response.added) {
                    $me.off('click')
                        .html('<span class="icon-checkmark"></span> Saved to List')
                        .disable();

                    TsmlSpeakers.count();

                    // Scroll to the top of the window to show the (added/updated) badge. For mobile & some tablet views, the badge will show on the hamburger menu icon, indicating My List is buried there.
                    window.scrollTo(0,0);
                    $('.hamburger-menu-button').addClass('speaker-added');
                    $('.menu-my-list > .global-nav-link').addClass('speaker-added');
                }
            });
    });


    var $page = $('#speakers-my-list-page');
    if($page.length) {
        loadSpeakersIn($page);
    }

    var $modalList = $('#speakers-my-list');

    if($modalList.length || $page.length) {

        // Get speakers when showing modal
        // ---------------------------------------
        $('#global-my-list-modal').on('hidden.bs.modal', function(){
            $modalList.html('<li class="text-center"><div class="loading-spinner"></div></li>');
            $('#my-speaker-list-email').collapse('hide');
        }).on('show.bs.modal', function(){

            loadSpeakersIn($modalList);

        });

        $('.speakers-review-list').on('click', '.remove', function(){
            if(!confirm('Are you sure you want to remove this speaker from your list?')) {
                return;
            }

            var $me = $(this);
            $me.disable();

            TsmlSpeakers.delete( $me.data('tsml-uuid') )
                .always(function(){ $me.enable(); })
                .fail(TsmlError.fail.bind($me))
                .done(function(response){
                    if(response.hasOwnProperty('deleted') && response.deleted) {
                        var hasSiblings = $me.closest('li.speaker').siblings('li.speaker').length;

                        if(!hasSiblings) {
                            $modalList.html(tsmlEmptyMarkup);
                            $modalList.closest('.speakers-review-list-wrapper').removeClass('speakers-list-has-items');
                        }

                        $me.closest('li.speaker').slideUp(250, function(){
                            $(this).remove();
                        });

                        TsmlSpeakers.count();
                    }
                });
        });

        // Clear all from list
        // ---------------------------------------
        $('.tsml-clear-list').on('click', function(){
            if(!confirm('Are you sure you want to remove all speakers from your list?')) {
                return;
            }

            var $me = $(this);
            $me.disable();

            TsmlSpeakers.clear()
                .always(function(){ $me.enable() })
                .fail(TsmlError.fail.bind($me))
                .done(function(response){
                    $modalList.html(tsmlEmptyMarkup)
                        .closest('.speakers-review-list-wrapper')
                        .removeClass('speakers-list-has-items');

                    $(".menu-my-list > .global-main-nav-link > .number-of-speakers-badge").remove();
                    $(".hamburger-menu-button > .number-of-speakers-badge").remove();

                    TsmlSpeakers.count();
                });

        });

        // Check all availability
        // ---------------------------------------
        $('.check-for-all').on('click', function(event){
            event.preventDefault();

            var $me = $(this);
            var goToURL = $me.attr('href');
            var sids = [];

            $me.closest('.speakers-review-list-wrapper').find('li.speaker').map(function(){
                sids.push('sid[]=' + encodeURIComponent($(this).attr('data-sid')));
            });

            if(sids.length) {
                goToURL += '?' + sids.join('&');
            }
            window.location = goToURL;
        });

        // Submit email form
        // ---------------------------------------
        $('#rb_speaker_list_share').on('submit', function(event){
            event.preventDefault();

            var $form = $(this);
            var $btns = $form.find(':button');
            var $spin = $btns.next('.loading-spinner');

            var data = $form.serialize();
            data += '&action=tg_send_speaker_list';

            $form.find('.alert').remove();

            $spin.removeClass('hide');
            $btns.disable();

            $.post(ajaxurl, data)
                .always(function(){
                    $btns.enable();
                    $spin.addClass('hide');
                })
                .fail(function(xhr){
                    console.log('Error sending speaker list', xhr);

                    var errors = ['An unknown error occurred. Please try again.'];
                    if(xhr.status == 400 || xhr.status == 404) {
                        errors = JSON.parse(xhr.responseText);
                    }

                    var $alert = $('<div>').attr('class', 'alert alert--error');
                    $alert.html( errors.join('<br>') );

                    $form.prepend($alert);
                })
                .done(function(response){
                    $('#my-speaker-list-email').collapse('hide');
                    $('#my-speaker-list-email-success').collapse('show');

                    setTimeout(function(){
                        $('#my-speaker-list-email-success').collapse('hide');
                    }, 2500);
                });

        }).on('click', '.cancel-email', function(){
            $('#my-speaker-list-email').collapse('hide');
        });

        $('#my-speaker-list-email #email_message').data('message', $('#my-speaker-list-email #email_message').val());

        $('#my-speaker-list-email').on('hidden.bs.collapse', function(){
            $(this).find('#email_to').val('');

            var $msg = $(this).find('#email_message');
            $msg.val( $msg.data('message') );
        });

        // Get speaker count
        // ---------------------------------------
        TsmlSpeakers.count();
    }

});
