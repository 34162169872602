//
// Accordion to Tabs JS
// --------------------------------------------------
// Props to https://codepen.io/chriscoyier/pen/kBdKH
// --------------------------------------------------

var AccordionToTabs = {

  el: {
    nav: $(".accordion-to-tabs"),
    tabs: $(".accordion-to-tabs__anchor"),
    panels: $(".accordion-to-tabs__content")
  },

  init: function() {
    this.bindUIActions();
  },

  bindUIActions: function() {
    this.el.nav
      .on(
        'click',
        'li > a:not(.is-active)',
        function(event) {
          AccordionToTabs.activateTab(event.target);
          event.preventDefault();
        }
      );
  },

  deactivateAll: function() {
    this.el.tabs.removeClass("is-active");
    this.el.panels.removeClass("is-open");
  },

  activateTab: function(el) {
    this.deactivateAll();

    $(el)
      .addClass("is-active")
      .next()
      .addClass("is-open");
  },

  // Activate a tab with a remote (non-"nav") element
  remoteActivateTab: function(href) {
    var tab_to_activate = this.el.tabs.filter('[href="' + href + '"]')[0];
    this.activateTab( tab_to_activate );
  }

};

AccordionToTabs.init();
