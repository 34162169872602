$(function($){

    var $modal = $('#publication-modal');
    var $form = $('#publication_form');

    function submitPubRequest(event) {
        event.preventDefault();

        var $form = $(this);
        var $btn = $form.find('[type="submit"]');
        var data = $form.serialize();
        data += '&action=tg_delivra_signup';

        $btn.disable();

        $.post(ajaxurl, data)
            .always(function(){
                $btn.enable();
            })
            .fail(function(xhr){
                console.log('Error submitting user data', xhr);
            })
            .done(function(response){
                $form.replaceWith(response);
            });
    }

    $modal
        .on('shown.bs.modal', function(){
            $form.on('submit', submitPubRequest);
        })
        .on('hidden.bs.modal', function(){
            $form.off('submit');
        });

});